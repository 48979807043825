/**
 * Created by nick on 1/9/2020.
 */
import React from "react"

import QuestionLayout from "../components/questionlayout"
import SEO from "../components/seo"
import progress1 from "../images/progress_bar_1.png"
import progress2 from "../images/progress_bar_2.png"
import progress3 from "../images/progress_bar_3.png"
import progress4 from "../images/progress_bar_4.png"
import progress5 from "../images/progress_bar_5.png"
import progress6 from "../images/progress_bar_6.png"

import SiteInfo from '../components/siteinformation'

import Helmet from 'react-helmet'



const Questions4 = () => (
    <div>
  <QuestionLayout>
<Helmet>
  <script src='/outboundsite.js' type="text/javascript" />
</Helmet>

<div className="container">
    <div className="question-header is-vertical-center content-centered">
      Take This Short Quiz
    </div>
  <div className="question">
    <h3>Are you age 25 or older and would you like to lose extra pounds quickly and safely? </h3>
  </div>
  <div className="progress-image">
    <progress className="progress is-primary" value="90" max="100"></progress>
  </div>
  <div className="columns is-centered">
  <div className="questions column is-half-desktop">
    <a  id="outboundBtn1" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;Yes</a>
    <a  id="outboundBtn2" className="quiz-button button"><i className="fa fa-square-o has-background-white"></i>&nbsp;No</a>
  </div>
  </div>
</div>
    <div className="content-spacer">
      . <br/>

    </div>

  </QuestionLayout>
      <div className="modal" id="outboundModal">
        <div className="modal-background"></div>
        <div className="modal-content">
          <div className="card">
            <div className="card-body content-centered">
            <div className="modal-title content-centered is-uppercase">Your results have been analyzed</div>
            <div className="modal-subtitle content-centered">Great News!  You're A Perfect Fit!</div>
                { getSite() == 1 &&
                  <a className="modal-div-button" id="submitButton1" href="https://hop.clickbank.net/?affiliate=nafpliotis&vendor=leptitox&cbpage=d3&h3=y&tid=p1">
                  <div className="modal-button-title is-uppercase">Show my personal results</div>
                  <div className="modal-button-subtitle">&amp; let me know more about the odd water hack</div>
                </a>
                }
                {
                  getSite() == 2 &&
                  <a className="modal-div-button" id="submitButton1" href="https://hop.clickbank.net/?affiliate=nafpliotis&vendor=leptitox&cbpage=d3&h3=y&tid=p2">
                    <div className="modal-button-title is-uppercase">Show my personal results</div>
                    <div className="modal-button-subtitle">&amp; let me know more about the odd water hack</div>
                  </a>
                }
                {
                  getSite() == 3 &&
                  <a className="modal-div-button" id="submitButton1" href="https://hop.clickbank.net/?affiliate=nafpliotis&vendor=leptitox&cbpage=d3&h3=y&tid=p3">
                    <div className="modal-button-title is-uppercase">Show my personal results</div>
                    <div className="modal-button-subtitle">&amp; let me know more about the odd water hack</div>
                  </a>
                }
                {
                  getSite() == 4 &&
                  <a className="modal-div-button" id="submitButton1" href="https://hop.clickbank.net/?affiliate=nafpliotis&vendor=leptitox&cbpage=d3&h3=y&tid=p4">
                    <div className="modal-button-title is-uppercase">Show my personal results</div>
                    <div className="modal-button-subtitle">&amp; let me know more about the odd water hack</div>
                  </a>
                }




              <div className="modal-footer content-centered"><span className="icon"><i className="fa fa-lock"></i></span>Your results are secure and will never be shared with anyone</div>
            </div>

          </div>
        </div>
        <button className="modal-close is-large" id="outboundModalClose" aria-label="close"></button>
      </div>
    </div>
)


function getSite() {
    var site = new SiteInfo().getSiteNumber();
    return site;
}

export default Questions4



